.filepond--drop-label {
    height: 200px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem !important;
    width: 100%;
}

.filepond--wrapper {
    font-size: 2rem !important;
}

.filepond--root {
    font-size: 2rem !important;
    max-height: 300px;
}

.filepond--item {
    font-size: 2rem !important;
    height: 45px
}